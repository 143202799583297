import React from "react";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/modal/modal";
import { MetroCancelIcon } from "../../components/icons/Icons";
import cartActions, { CartActionMap } from "../../actions/cart.action";
const ProductDispatchMessagePage = (props) => {
  const { toggleModal, isOpen } = props;
  const ModalCloseIcon = () => (
    <button
      type="button"
      className="close close_icon ml-auto"
      aria-label="Close"
      onClick={toggleModal}
    >
      <span aria-hidden="true">
        <MetroCancelIcon />
      </span>
    </button>
  );

  const dispatch = useDispatch();
  const CloseModal = () => {
    dispatch(cartActions.closeAllModals());
  };
  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        toggleModal={toggleModal}
        closeIcon={<ModalCloseIcon />}
        id="PlaceOrderModal"
        centered
        className="modal-dialog modal-xs modal-dialog-centered"
      >
        {
          <>
            <div className="modal-header">
              <h5 className="placeorder-modal-title">
                Thank you for your Order. Once admin will approve, your order
                will be dispatched{" "}
              </h5>
              <button
                type="button"
                className="modal-fill_btn_confirm_order btn btn-sm"
                onClick={CloseModal}
              >
                OKay
              </button>
            </div>
          </>
        }
      </ModalComponent>
    </>
  );
};

export default ProductDispatchMessagePage;
