import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ContactusLinks } from "../../utils/constants";
import ContactUsForm from "./contactUsForm";
import { getSocialLinksAsync } from "../../actions/faq.action";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { socialLinksList, refreshLinkData } = useSelector(
    (state) => state.faq
  );
  const data = useSelector((state) => state.homepageContent.contactUsDetais);

  useEffect(() => {
    if (refreshLinkData) {
      dispatch(getSocialLinksAsync());
    }
  }, [refreshLinkData]);

  return (
    <section className="container-fluid">
      <div className="row contact_page contact_us">
        <div className="col-lg-4 col-md-12">
          <div className="contactus_div">
            <h2 className="title">CONTACT US</h2>
            <h6>Get In Touch</h6>
            {data ? (
              <>
                <p>
                  <a>
                    <b>A: &nbsp;</b>
                    {data.address}
                  </a>
                </p>
                <p>
                  <a>
                    <b>E: &nbsp;</b>
                    {data.email}
                  </a>
                </p>
                <p>
                  <a>
                    <b>P: &nbsp;</b>
                    {data.contact}
                  </a>
                </p>
              </>
            ) : (
              []
            )}
          </div>
        </div>
        <div className="col-lg-5 col-md-12">
          <div className="news_letter">
            <ContactUsForm />
          </div>
        </div>
        <div className="col-lg-3 col-md-12">
          <ul className="list-unstyled text-small contact_social">
            {socialLinksList.facebook && (
              <li>
                <a href={socialLinksList.facebook}>FACEBOOK</a>
              </li>
            )}
            {socialLinksList.twitter && (
              <li>
                <a href={socialLinksList.twitter}>TWITTER</a>
              </li>
            )}
            {socialLinksList.pinterest && (
              <li>
                <a href={socialLinksList.pinterest}>PRINTEREST</a>
              </li>
            )}
            {socialLinksList.google && (
              <li>
                <a href={socialLinksList.google}>GOOGLE</a>
              </li>
            )}
            {socialLinksList.instagram && (
              <li>
                <a href={socialLinksList.instagram}>INSTAGRAM</a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
